import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import placeholderspeakerimage from "../berlinbear.png"
import Header from "../components/header"

import Grid from "@material-ui/core/Grid"

import Label from "@material-ui/icons/Label"
import Avatar from "@material-ui/core/Avatar"

import Close from "@material-ui/icons/Close"

import IconButton from "@material-ui/core/IconButton"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import { kebabCase } from "lodash"

import ReactPlayer from "react-player"

// Render a YouTube video player

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: ".2em",
  },
  backButton: {
    textDecoration: "none",
  },
  card: {
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "95vw",
    },
    "& div": {
      display: "flex",
      justifyContent: "center",
    },
  },
  player: {
    [theme.breakpoints.down("sm")]: {
      width: "98vw !important",
    },
  },
  cardContent: {
    height: 200,
  },

  title: {
    lineHeight: 1.2,
    fontsize: "1.3rem",
  },
  channelName: {
    color: "#616161",
  },
  tagArea: {
    marginTop: 10,
  },
  chipLabel: {
    color: "#c5c5c5",
  },
  channelNameChips: {
    marginTop: 5,
    background: "none",
  },
  IGEmbed: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  GridParent: {
    padding: 20,
    paddingBottom: 0,
    display: "flex",
    maxHeight: 600,
    alignItems: "flex-start",
    justifyContent: "center",
    maxWidth: 1000,
    [theme.breakpoints.down("sm")]: {
      width: "96vw",
      padding: 10,
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: 1000,
      maxWidth: 1000,
    },
    margin: "0 auto",
  },

  filteredItemsLength: {
    textAlign: "left",
    fontWeight: 400,
  },
  tagChips: {
    margin: "2px 2px 2px 0px",
    height: 18,
    fontSize: 13,
    backgroundColor: "#acacac",
    color: "white",
  },
  SuperDuperGrandParent: {
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
    },
  },
  GridItem: {
    padding: 20,
  },
})

const ItemPage = ({ data, classes }) => {
  //console.log(data);
  const item = data.item
  //console.log(item.tags)

  const url = `https://www.youtube.com/watch?v=${item.video_id}`

  return (
    <ModalRoutingContext.Consumer>
         {({ modal, closeTo }) => (
        <div className={classes.SuperDuperGrandParent}>
          {modal ? (
            <Link to={closeTo}>
              <IconButton className={classes.button} aria-label="Close">
            <Close />
          </IconButton>
          </Link>
          ) : (
            <></>
          )}

          <Grid container spacing={0} className={classes.GridParent}>
            <Grid item lg={9} md={9} xs={12} className={classes.GridItem}>
              <ReactPlayer controls={true} url={url} />
            </Grid>

            <Grid item lg={3} md={3} xs={12} className={classes.GridItem}>
              <Typography
                className={classes.filteredItemsLength}
                variant="title"
                component="h4"
              >
                Channel
              </Typography>
              <Link
                to={`/channelName/${kebabCase(item.channelName)}/`}
                style={{ textDecoration: "none" }}
              >
                <Chip
                  label={item.channelName}
                  variant="outlined"
                  clickable
                  className={classes.channelNameChips}
                />
              </Link>
              <br /> <br />
              <div className={classes.tagArea}>
                <Typography
                  className={classes.filteredItemsLength}
                  variant="title"
                  component="h4"
                >
                  Explore these tags <br />
                </Typography>

                {item.tags.map(tag => (
                  <Link
                    to={`/tag/${kebabCase(tag)}/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      clickable
                      label={"#" + tag}
                      key={tag.toString()}
                      className={classes.tagChips}
                    />
                  </Link>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default withStyles(styles)(ItemPage)

export const ItemPageQuery = graphql`
  query ItemDetailsQuery($video_id: String) {
    item(video_id: { eq: $video_id }) {
      video_id

      title
      thumbnail_link
      tags
      channelName
      parent {
        id
      }
      description
      children {
        id
      }
    }
  }
`
